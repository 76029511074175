import { Dispatch } from "../ProgramTypes";


let rapier;

export async function importPhysicsEngine(dispatch: Dispatch) {
  if (!rapier) {
    try {
      rapier = await import("@dimforge/rapier3d-compat");
      await rapier.init();

    } catch (err) {
      const message = `当前浏览器物理引擎不兼容, 请使用 谷歌Chrome, 安卓Android, 腾讯QQ, 微软Edge 浏览器打开`;
      dispatch({ id: "error", message, stack: err.stack });
      return;
    }
  }

  dispatch({
    id: "importedPhysicsEngine",
    physicsEngine: rapier,
  });
}
