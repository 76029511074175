import { Vector3 } from "three";
import { WorldDoc } from "~/y-integration/WorldDoc";

import { Dispatch } from "../ProgramTypes";

export const getPositionFromEntryway =
  (worldDoc: WorldDoc, entryway: string) => async (dispatch: Dispatch) => {
    const entrywayUnsub = worldDoc.entryways.subscribe(($entryways) => {
      if (!$entryways.has(entryway)) entryway = "default";
      const position_default = $entryways.get(entryway);
      const position = $entryways.get(entryway);

      //随机化
      if (position) {

        position[0]=Math.round(Math.random() * 6)-3+position_default[0];
        position[1]=position_default[1];
        position[2]=Math.round(Math.random() * 6)-3+position_default[2];

        console.log(position)
        const entrywayPosition = new Vector3().fromArray(position);
 
        dispatch({ id: "gotPositionFromEntryway", entryway, entrywayPosition });
      }
    });
    dispatch({ id: "gotEntrywayUnsub", entrywayUnsub });
  };
