<script lang="ts">
  import PageOverlay from "~/ui/lib/PageOverlay";
  import relmLogo from "~/ui/MediaSetup/relm-logo.png";
  $$props;


  let postLoading = null;

window.addEventListener("message", (e) => {
  postLoading = e.data;
});
  const complete_url = window.location.href
  const url = new URL(complete_url);
  const path = url.pathname.split('/')[1];//.pop();
  postLoading = `https://yshi-bj.oss-accelerate.aliyuncs.com/image/vrspace/logo/${path||'loading'}.png`
</script>

<PageOverlay>
  <r-column>
    <div class="logo">
      {#if postLoading}
      <img src={postLoading} alt="logo"  width="150" />
    {:else}
    <img src={relmLogo} alt="logo" width="150" />
    {/if}
  
    </div>
    <slot />
  </r-column>
</PageOverlay>

<style>
  r-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .logo {
    text-align: center;
  }
</style>
