import { readable } from "svelte/store";

export const rapier = readable(null, (set) => {
  import("@dimforge/rapier3d-compat")
    .then((rapier) => {
      set(rapier);
    })
    .catch((error) => {
      console.error("当前浏览器安装包不兼容, 请使用 谷歌Chrome, 安卓Android, 腾讯QQ, 微软Edge 浏览器打开", error.message, error.stack);
    });
});
